import React, { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//Pages
import DevReports from "./pages/devReports";
import Individual from "./pages/individual";
import SignIn from "./pages/signIn";
//Styles


function App() {
  
  const [selectedUser , setSelectedUser] = useState(null);
  const [loginStatus, setLoginStatus] = useState(-1);
  const [enquiryData, setEnquiryData] = useState(-1);
  const [userId, setUserId] = useState(0);
  const [token, setToken] = useState(0);

  return (
    <Router>
      {/* <Header /> */}
      <Route
        render={({ location }) => (
          <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route
                exact
                path='/devReports'
                render={() => <DevReports setSelectedUser={setSelectedUser} loginStatus={loginStatus} setLoginstatus={setLoginStatus} userId={userId} token={token} setToken={setToken} setEnquiryData={setEnquiryData}/>}
              />
               <Route
                exact
                path='/'
                render={() => <SignIn setSelectedUser={setSelectedUser} loginStatus={loginStatus} setLoginStatus={setLoginStatus} userId={userId} setUserId={setUserId} token={token} setToken={setToken} />}
              />
               <Route
                exact
                path='/individual'
                render={() => <Individual selectedUser={selectedUser} loginStatus={loginStatus} setLoginStatus={setLoginStatus} userId={userId} token={token} setToken={setToken} enquiryData={enquiryData} setEnquiryData={setEnquiryData}/>}
              />
            </Switch>
          </AnimatePresence>
        )}
      />
    </Router>
  );
}

export default App;
