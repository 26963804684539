import React, { useState, useEffect } from "react";
import { makeStyles ,withStyles,TextField} from "@material-ui/core";
import { Link ,useHistory} from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment"

const CustomTextField = withStyles({
  root: {
    padding: 0,
    "& label.Mui-focused": {
      color: "#4f2202",
    },
    "& label": {
      color: "#4f2202",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#4f2202",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#4f2202",
      },
      "&:hover fieldset": {
        borderColor: "#4f2202",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#4f2202",
      },
    },
  },
})(TextField);

const useStyles = makeStyles((theme) => ({
  primary: {
    width:"100%",
    display: "flex",
    flexDirection: "column",
    padding:"3vh",
    minHeight:"94vh",
    height:"100%",
    flex:1,
    flexGrow:1,
    overflow:"hidden",
    // backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
    // content: "",
    justifyContent:"flex-start",
    fontFamily:"Source",
    backgroundColor: "#f0d8bb"
  },
  button:{
    alignItems:"center",
    fontSize:"1.3rem",
    marginTop:10,
    display:"flex",
    justifyContent:"center",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
      cursor: "pointer",
    }
  }
}));

export default function SignIn (props) {

  const {setLoginStatus,token,setToken,setUserID} = props
  const classes = useStyles();
  const history = useHistory();
  const [username , setUsername] = useState("");
  const [password , setPassword] = useState("");
  const [errMsg , setErrMsg] = useState("");
  const [incorrect , setIncorrect] = useState(false);


  const login = () =>{
    
    fetch("https://fimsapi.bluekaktus.com/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        {
        loginParams:
          {
            loginID:username,
            password:password
          }
        }
      ),
    })
      .then((response) => {
        if (response.status == 200) {
          setIncorrect(false)
          console.log("Sucess");
          return response.json();
        } else {  
          // setLoginStatus(-1);
          // setIncorrect(true)
          // notify("error","Incorrect Details")
          setPassword("")
          return response.json();
        }
      })
      .then((responseJson) => {
        console.log(responseJson)
        if(responseJson.result=="Got login data successfully"||responseJson.userRole=="Intermediary"){
          setLoginStatus(1)
          setIncorrect(false)
          setToken(responseJson.token)
          setTimeout(()=>{history.push("/devReports")}, 500);
        }else if(responseJson.userRole!="Intermediary"){
          setPassword("")
          setErrMsg("You do not have access to this website, pls contact admin.")
        }else{
          setPassword("")
          setErrMsg("Incorrect details entered.")
        }
      })
      .catch((error) => {
        console.log("ERROR: ", error);
      });
}

console.log(moment("25-Sep-2023 14:18:09").format("DD-MMM-YYYY HH"))
console.log("25-Sep-2023 14:18:09")

  return (
    <motion.div 
      className={classes.primary}
      >
      <motion.div
        initial={{
          backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
          content: "",
          height: "300%",
          left: "-50%",
          opacity: .23,
          zIndex:2,
          position: "fixed",
          top: "-100%",
          width: "300%",
        }}
        animate={{
          translate:[("0","0"),("-5%", "-10%"),("-5%", "5%"),("7%", "-5%"),("-5%", "5%"),("5%", "0%"),("-0%", "5%"),("3%", "5%"),("0%", "0%")]
        }}
        transition={{
          duration: .2,
          ease: "linear",
          repeat: Infinity,
          repeatDelay: 0
        }}
      >

      </motion.div>
      <motion.div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"90vh",width:"100%",zIndex:3}}>
        <div style={{width:"30%"}}>
        <CustomTextField
          style={{ flex: 1 }}
          fullWidth
          label="Username"
          variant="outlined"
          value={username}
          inputProps={{ style: { color: "#4f2202" } }}
          onChange={(e) => {
            setUsername(e.target.value);
          }}
        />       
         <CustomTextField
          style={{ flex: 1 ,marginTop:10 }}
          fullWidth
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          inputProps={{ style: { color: "#4f2202"} }}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
        />         
        <div  className={classes.button} onClick={()=>login()} >
            <div style={{fontSize:"1.3rem",padding:"5px 15px",backgroundColor:"#4f2202DD",color:"#FFFFFF",borderRadius:8}}>LOGIN</div>
        </div>       
        <div className={classes.button} >
            <div style={{fontSize:"1rem",padding:"5px 15px",color:"#c8102e"}}>{errMsg}</div>
         </div>              
        </div>
      </motion.div>
    </motion.div>
  )
}
