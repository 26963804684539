import React, { useState, useEffect } from "react";
import { 
  makeStyles ,
  withStyles,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,} from "@material-ui/core";
import { Link ,useHistory} from "react-router-dom";
import { motion } from "framer-motion";
import ExplicitIcon from '@material-ui/icons/Explicit';
const useStyles = makeStyles((theme) => ({

  primary: {
    width:"100%",
    display: "flex",
    flexDirection: "column",
    padding:"3vh",
    minHeight:"94vh",
    height:"100%",
    flex:1,
    flexGrow:1,
    overflow:"hidden",
    // backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
    // content: "",
    justifyContent:"flex-start",
    fontFamily:"Source",
    backgroundColor: "#f0d8bb"
  },
  tableWrapper:{
    display:"flex",
    width:"95vw",
    flexDirection:"column",
    zIndex:3
  },
  header:{
    marginTop:16,
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.5rem"
  },
  row:{
    margin:"8px 0px",
    width:"100%",
    display:"flex",
    flexDirection:"row",
    justifyContent:"flex-start",
    alignItems:"center",
    fontSize:"1.3rem",
  },
  table:{
    display:"flex",
    flexDirection:"column",
    margin:"8px 0px",
  },
  tableCell:{
    width:"15%",
    transition: "all .2s ease-in-out",
    textAlign:"left",
    "&:hover": {
      fontWeight: "bold",
      cursor: "pointer",
    },
  },
  select: {
    color: "#4f2202",
    padding:"12px !important"
  },
  icon: {
    color: "#4f2202",
  },

}));

function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
  var color1 = rgbColor1;
  var color2 = rgbColor2;
  var fade = fadeFraction;

  // Do we have 3 colors for the gradient? Need to adjust the params.
  if (rgbColor3) {
    fade = fade * 2;

    // Find which interval to use and adjust the fade percentage
    if (fade >= 1) {
      fade -= 1;
      color1 = rgbColor2;
      color2 = rgbColor3;
    }
  }

  var diffRed = color2.red - color1.red;
  var diffGreen = color2.green - color1.green;
  var diffBlue = color2.blue - color1.blue;

  var gradient = {
    red: parseInt(Math.floor(color1.red + (diffRed * fade)), 10),
    green: parseInt(Math.floor(color1.green + (diffGreen * fade)), 10),
    blue: parseInt(Math.floor(color1.blue + (diffBlue * fade)), 10),
  };
  return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ', .9'+')';
}

const OutlinedInputL = withStyles((theme) => ({
  notchedOutline: {
    borderColor: "#4f2202 !important",
  },
}))(MuiOutlinedInput);

export default function DevReports (props) {
  const {setSelectedUser,loginStatus,token,setToken,setEnquiryData} = props
  const classes = useStyles();
  const history = useHistory();
  const [data , setData] = useState([]);
  const [dateRange,setDateRange] = useState({time:"day",depth:"today"})
  const [showDepth , setShowDepth] = useState(false);
  const [depthData , setDepthData] = useState([]);
  const [exit , setExit] = useState(false);
  const [exitx , setExitx] = useState(false);
  const [showClients , setShowClients] = useState(false);
  const [client, setClient] = React.useState(0);
  const [clients, setClients] = React.useState([]);
  const [project, setProject] = React.useState("");
  const [projects, setProjects] = React.useState([]);

  let lowColor = {
    red: 231,
    green: 24,
    blue: 55
  };
  let mediumColor = {
    red: 255,
    green: 231,
    blue: 0
  };
  let highColor = {
    red: 60,
    green: 179,
    blue: 113
  };

  const getData = () =>{
    fetch(`https://fimsapi.bluekaktus.com/api/getMarketplaceIntermediary`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        result.result.forEach(item => {
          let approved = 0 
          item.FABRIC_LIST.forEach((item2)=>{
            if(item2.IS_APPROVED){
              approved=approved+1
            }
          })
          item.fabrics=item.FABRIC_LIST.length
          item.approved=approved
          item.pending=item.FABRIC_LIST.length-approved
        });
        setData(result.result)
      });
  }

  useEffect(() => {
    getData()
    if(loginStatus<=0){
      history.push("/")
    }
  }, []);

  const container = {
    hidden: { opacity: 0 ,y:-20},
    show: {
      opacity: 1,
      y:0,
      transition: {
        staggerChildren: 0.05
      }
    },
    exit: { opacity: 0 ,y:-20,
      transition: {
      staggerChildren: 0.05
    }},
  }
  const containerx = {
    hidden: { opacity: 0 ,x:-20},
    show: {
      opacity: 1,
      x:0,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    },
    exit: {
      opacity: 0,
      x:-20,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    }
  }
  
  const itemx = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  const sortData=(key)=>{
    setData([])
    var temp = JSON.parse(JSON.stringify(data))
    temp.sort((a,b) => a[key] - b[key]).reverse()
    setData(temp)
    setExitx(false)
  }

  return (
    <motion.div 
      className={classes.primary}
      >
      <motion.div style={{display:"flex",flexDirection:"row",width:"90%"}}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          onClick={()=>{
            setProject("")
            setClient("")
            setClients([])
            setShowClients(false)
            getData(dateRange.time,dateRange.depth,0,0)
          }}
          style={{fontSize:"2.8rem",color:"#4f2202",display:"flex",flexDirection:"row",width:"40%",zIndex:3}}>
          {[..."Fabric-Enquiries"].map((item, index) => (
                  <motion.div
                    variants={itemx}
                    whileHover={{ scale: 1.02 }}
                  >
                    {item}
                  </motion.div>
                ))}
        </motion.div>
      </motion.div>
      <div className={classes.tableWrapper}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          className={classes.header}>
          <div style={{width:"10%"}} 
          onClick={()=>{
            setExitx(true)
            setTimeout(()=>{sortData("FABRIC_ENQUIRY_ID")}, 500);
            }}>
            Enquiry ID
          </div>
          <div style={{width:"15%",textAlign:"left",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("COMPANY_NAME")}, 500);
              }}>
             Brand
          </div>
          <div style={{width:"15%",textAlign:"left",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("USER_NAME")}, 500);
              }}>
             User
          </div>
          <div style={{width:"15%",textAlign:"left",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("ADDED_ON")}, 500);
              }}>
             Date
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("fabrics")}, 500);
              }}>
            Number of Fabrics
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("approved")}, 500);
              }}>
            Approved
          </div>
          <div style={{width:"15%",textAlign:"right",borderRadius:8}}
            onClick={()=>{
              setExitx(true)
              setTimeout(()=>{sortData("pending")}, 500);
              }}>
            Pending
          </div>
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exitx?"exit":"show"}
          className={classes.table}>
          {data.map((item, index) => (
                <motion.div
                  variants={itemx}
                  className={classes.row}
                >
                  <div 
                    onClick={()=>{

                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                    style={{width:"10%",fontSize:"1.7rem"}}>
                    {item.FABRIC_ENQUIRY_ID}
                  </div>
                  <motion.div
                   className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"left",padding:3,borderRadius:8}}>
                    {item.COMPANY_NAME}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"left",padding:3,borderRadius:8}}>
                      {item.USER_NAME}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"left",padding:3,borderRadius:8}}>
                      {item.ADDED_ON}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.fabrics}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.approved}
                    </motion.div>
                  </motion.div>
                  <motion.div className={classes.tableCell}>
                    <motion.div 
                      onClick={()=>{
                      setExit(true)
                      setExitx(true)
                      setEnquiryData(item)
                      setTimeout(()=>{history.push("/individual")}, 500);
                    }}
                      whileHover={{ scale: 1.03 }}
                      transition={{ type: "spring", stiffness: 400, damping: 10 }}
                      style={{margin:"0px",textAlign:"right",padding:3,borderRadius:8}}>
                      {item.pending}
                    </motion.div>
                  </motion.div>
                </motion.div>
              ))}
        </motion.div>
      </div>
    </motion.div>
  )
}
