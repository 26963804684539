import React, { useState, useEffect } from "react";
import { makeStyles ,withStyles,Checkbox} from "@material-ui/core";
import { Link ,useHistory} from "react-router-dom";
import { motion } from "framer-motion";
import toast, { Toaster } from "react-hot-toast";

const GreenCheckbox = withStyles({
  root: {
    color: "#c8102eAA",
    width: 10,
    height: 10,
    '&$checked': {
      color: "#7eb293AA",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({

  primary: {
    width:"100%",
    display: "flex",
    flexDirection: "row",
    padding:"3vh",
    minHeight:"94vh",
    height:"100%",
    flex:1,
    flexGrow:1,
    overflow:"hidden",
    // backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
    // content: "",
    justifyContent:"flex-start",
    fontFamily:"Source",
    backgroundColor: "#f0d8bb"
  },
left:{
    display:"flex",
    width:"100vw",
    flexDirection:"column",
    zIndex:3
  },
  right:{
    display:"flex",
    width:"55vw",
    flexDirection:"column",
    zIndex:3
  },
  imageCard:{
    margin:"5px",
    width:"21vw",
    display:"flex",
    flexDirection:"column",
    justifyContent:"center",
    alignItems:"center",
    fontSize:"1.3rem",
    borderRadius:8
  },
  button:{
    alignItems:"flex-start",
    fontSize:"1.3rem",
    marginTop:10,
    display:"flex",
    justifyContent:"flex-start",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.03)",
      cursor: "pointer",
    }
  }
}));


export default function Individual (props) {
  const {enquiryData,setEnquiryData,token,userID} = props
  const classes = useStyles();
  const history = useHistory();
  const [exit , setExit] = useState(false);

  const container = {
    hidden: { opacity: 0 ,y:-20},
    show: {
      opacity: 1,
      y:0,
      transition: {
        staggerChildren: 0.05
      }
    },
    exit: { opacity: 0 ,y:-20,
      transition: {
      staggerChildren: 0.05
    }},
  }
  
  const itemx = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }
  const containerx = {
    hidden: { opacity: 0 ,x:-20},
    show: {
      opacity: 1,
      x:0,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    },
    exit: {
      opacity: 0,
      x:-20,
      transition: {
        delay:.5,
        staggerChildren: .1,
        delayChildren:.5
      }
    }
  }

  return (
    <motion.div 
      className={classes.primary}
      >
          <Toaster
            position="bottom-right"
            toastOptions={{
              // Define default options
              className: "",
             
              style: {
                color: " #FFFFFF",
                background: "#4f2202",
              },
              success: {
                duration: 4000,
                style: {
                  color: " #666666",
                  background: "#FFFFFFDD",
                },
              },
              error: {
                duration: 2000,
                style: {
                  color: " #666666",
                  background: "#FFFFFFDD",
                },
              },


            }}
          />
      <motion.div
        initial={{
          backgroundImage: "url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/9632/paper-pattern.png')",
          content: "",
          height: "200%",
          left: "-50%",
          opacity: .23,
          zIndex:2,
          position: "fixed",
          top: "-100%",
          width: "200%",
        }}
        animate={{
          translate:[("0","0"),("-5%", "-10%"),("-5%", "5%"),("7%", "-5%"),("-5%", "5%"),("5%", "0%"),("-0%", "5%"),("3%", "5%"),("0%", "0%")]
        }}
        transition={{
          duration: .2,
          ease: "linear",
          repeat: Infinity,
          repeatDelay: 0
        }}
      >

      </motion.div>
      <div className={classes.left}>
        <motion.div 
          variants={container}
          initial="hidden"
          animate={exit?"exit":"show"}
          style={{fontSize:"3rem",fontWeight:"vold",color:"#4f2202",display:"flex",flexDirection:"row",width:"100%",zIndex:3}}>
          {[...enquiryData.FABRIC_ENQUIRY_ID.toString()].map((item, index) => (
                  <motion.div
                    variants={itemx}
                    whileHover={{ scale: 1.02 }}
                  >
                    {item}
                  </motion.div>
                ))}
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exit?"exit":"show"}
          style={{fontSize:"1.5rem",color:"#4f2202DD",display:"flex",flexDirection:"column",zIndex:3}}>
            <div>{"Inquiry by " + enquiryData.USER_NAME + " @ "+ enquiryData.COMPANY_NAME}</div>
            <div>{enquiryData.ADDED_ON}</div>
            <div>{"Approved: " + enquiryData.approved}</div>
            <div>{"Pending: "+enquiryData.pending}</div>
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exit?"exit":"show"} 
          className={classes.button}>
          <div style={{fontSize:"1.3rem",padding:"5px 15px",backgroundColor:"#4f2202DD",color:"#FFFFFF",borderRadius:8}}
          onClick={()=>{
            let flag = false
            let temp = []
            let temp2 = []
            enquiryData.FABRIC_LIST.forEach((item)=>{
              temp.push({fabricID:item.FABRIC_ID,isApproved:item.FABRIC_ID?1:0})
              if(item.IS_APPROVED){
                flag=true
              }
            })
            enquiryData.FABRIC_LIST.forEach((item)=>{
              temp2.push({fabricID:item.FABRIC_ID,qty:item.EXCESS_QTY})
            })
            fetch(`https://fimsapi.bluekaktus.com/api/approveFabricEnquiry`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
              body:JSON.stringify({
                fabricEnquiryID:enquiryData.FABRIC_ENQUIRY_ID,
                fabrics:temp
              })
            })
              .then((response) => response.json())
              .then((result) => {
                toast("Enquiry Sent");
                fetch("https://fimsapi.bluekaktus.com/api/sendMailgenPPT", {
                        method: "POST",
                        body: JSON.stringify({
                          userID:userID,
                          fabrics:temp
                        }),
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: "Bearer " + token,
                        },
                      })
                        .then((response) => response.json())
                        .then((responseJson) => {
                          console.log(responseJson)
                        })
              });
          }}
          >Send Enquiry</div>
        </motion.div>
        <motion.div 
          variants={containerx}
          initial="hidden"
          animate={exit?"exit":"show"}
          style={{flexDirection:"row",display:"flex",overflow:"wrap",width:"100vw",flexWrap:"wrap"}}>
              {enquiryData.FABRIC_LIST.map((item, index) => (
                <motion.div
                  variants={itemx}
                  className={classes.imageCard}
                  style={{border: item.IS_APPROVED?"3px solid #7eb293AA":"3px solid #c8102eAA"}}
                >
                  <div className={classes.thumbInner}>
                      <img
                        src={item.IMAGE_PATH}
                        style={{ width:"20vw",margin: 12,borderRadius:8,marginBottom:0 }}
                      />
                  </div>
                  <div style={{fontSize:"1.1rem",color:"#4f2202",padding:0,marginLeft:15,textAlign:"left",width:"100%"}}>
                    {item.FABRIC_NAME}
                  </div>
                  <div style={{fontSize:"1.1rem",color:"#4f2202",padding:0,marginLeft:15,textAlign:"left",width:"100%"}}>
                    {item.COMPANY_NAME}
                  </div>
                  <div style={{fontSize:"1.1rem",color:"#4f2202",padding:0,marginLeft:15,textAlign:"left",width:"100%"}}>
                    {"Rate : "+item.RATE}
                  </div>
                  <div style={{fontSize:"1.1rem",color:"#4f2202",padding:0,marginLeft:15,textAlign:"left",width:"100%",marginBottom:10}}>
                    {"Qty : "+item.EXCESS_QTY}
                  </div>
                  {/* <div style={{display:"flex",flexDirection:"row"}} onClick={()=>{
                     let temp = JSON.parse(JSON.stringify(enquiryData))
                     if(!item.IS_APPROVED&&!item.original){
                       temp.FABRIC_LIST[index].IS_APPROVED = ! temp.FABRIC_LIST[index].IS_APPROVED
                       temp.FABRIC_LIST[index].original = true
                       console.log("Aye")
                     }else if(item.IS_APPROVED&&item.original){
                      temp.FABRIC_LIST[index].IS_APPROVED = ! temp.FABRIC_LIST[index].IS_APPROVED
                      temp.FABRIC_LIST[index].original = false
                     }
                     console.log(temp)
                     setEnquiryData(temp)
                  }}>
                      <GreenCheckbox checked={item.IS_APPROVED} />
                        <div
                          style={{
                            color: "#4f2202",
                          }}
                        >
                          {item.IS_APPROVED?"Approved":"Pending Approval"}
                        </div>
                  </div> */}
                </motion.div>))}
        </motion.div>
      </div>
    </motion.div>
  )
}
